import React from 'react'
import { Grid, Typography} from '@material-ui/core';
import { CardMedia, Card, CardContent } from '@material-ui/core';
import Researchimageone from '../../../assets/Researchimageone.png';
import Researchimagetwo from '../../../assets/Researchimagetwo.png';
import Researchimagethree from '../../../assets/Researchimagethree.png';
import ResearchLandingTile from '../../../staticcomponents/ResearchLandingTile'
import { Link } from 'gatsby';
import './ResourceHubResearch.scss'
import Container from '@material-ui/core/Container';

export default function ResourcehubResearch() {

    return (
        <>
            <Container maxWidth="xl" className='padding-none'>
                <Grid container className="research-cls">
                    <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="research-container">
                        <Grid item xl={2} lg={12} md={12} sm={12} xs={12}>
                            <h2 gutterBottom className="title">
                                research
                            </h2>
                        </Grid>
                        <Grid container item xl={10} lg={12} md={12} sm={12} xs={12} className="research-card-container">
                            {/* <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <Link to={'/ResearchContent/'} role="link" className='container-link'>
                                <Card className="research-card">
                                    <CardMedia
                                        component="img"
                                        alt="green iguana"
                                        height="240"
                                        image={Researchimageone}
                                    />
                                    <CardContent className="card-content">
                                        <Grid item container spacing={2}>
                                            <Grid item direction="column">
                                                <div className="publishing-date">
                                                    SURVEYS / JUNE 2022
                                                </div>
                                                <div className="research-title">
                                                    VIRTUAL ASSIGNMENTS PULSE SURVEY
                                                </div>
                                            </Grid>

                                        </Grid>
                                        <div className="description">
                                            Recently, Cartus conducted a survey to determine whether or not clients and prospects are using—or are considering using—Virtual Assignments.
                                        </div>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <Link to={'/ResearchContent/'} role="link" className='container-link'>
                                <Card className="research-card">
                                    <CardMedia
                                        component="img"
                                        alt="green iguana"
                                        height="240"
                                        image={Researchimagetwo}
                                    />
                                    <CardContent className="card-content">
                                        <Grid item container spacing={2}>
                                            <Grid item direction="column">
                                                <div className="publishing-date">
                                                    SURVEYS / JUNE 2022
                                                </div>
                                                <div className="research-title">
                                                    VIRTUAL ASSIGNMENTS PULSE SURVEY
                                                </div>
                                            </Grid>

                                        </Grid>
                                        <div className="description">
                                            Recently, Cartus conducted a survey to determine whether or not clients and prospects are using—or are considering using—Virtual Assignments.
                                        </div>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <Link to={'/ResearchContent/'} role="link" className='container-link'>
                                <Card className="research-card">
                                    <CardMedia
                                        component="img"
                                        alt="green iguana"
                                        height="240"
                                        image={Researchimagethree}
                                    />
                                    <CardContent className="card-content">
                                        <Grid item container spacing={2}>
                                            <Grid item direction="column">
                                                <div className="publishing-date">
                                                    SURVEYS / JUNE 2022
                                                </div>
                                                <div className="research-title">
                                                    VIRTUAL ASSIGNMENTS PULSE SURVEY
                                                </div>
                                            </Grid>

                                        </Grid>
                                        <div className="description">
                                            Recently, Cartus conducted a survey to determine whether or not clients and prospects are using—or are considering using—Virtual Assignments.
                                        </div>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid> */}
                            <ResearchLandingTile />

                            {/* More Blogs Text */}
                            <Grid container spacing={1} className="more-research-text">
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="more-research-col">
                                    <Typography className="more-research-text">+ more research</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Container>
        </>
    )
}


