import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloseIcon from "@material-ui/icons/Close";
import { Button, Dialog, DialogContent, Slide, IconButton, Box } from '@material-ui/core';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import video_1 from '../../../assets/research_2.png';
import video_2 from '../../../assets/video_2.png';
import video_3 from '../../../assets/video_3.png';
import './ResourceHubVideo.scss'
import { Link } from 'gatsby';
import Container from '@material-ui/core/Container';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
export default function ResourceHubVideo() {

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Container maxWidth="xl" className='padding-none'>

            <Grid container className="resourcehub-video-cls">

                <Grid item xl={0} lg={3} md={2} sm={2} xs={0}></Grid>
                <Grid container xl={12} lg={6} md={8} sm={8} xs={12} spacing={2} className="container-padding">
                    {/* **********************Title******************** */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <h2 className="title">videos</h2>
                    </Grid>
                    {/* **********************video content******************** */}
                    <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} spacing={2} >
                        {/* video 1 tile */}
                        <Grid item xl={4} lg={12} md={12} sm={12} xs={12}>
                            <Paper elevation={0} variant="outlined" className="video-container">
                                <Button className="button-container" onClick={handleClickOpen}>

                                    <img src={video_1}
                                        alt="video"
                                        className="video-img" />
                                    <div className="video-img-overlay"></div>
                                    <PlayCircleIcon fontSize="large" className="play-button-icon" />
                                </Button>
                                <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} maxWidth="md">
                                    <Box className="popup-overlay">
                                        <IconButton className="popup-close-icon" onClick={handleClose}>
                                            <CloseIcon />
                                        </IconButton>
                                        <DialogContent >
                                            <Grid container className='dialog-content'>
                                                <div className="iframe-class">
                                                    <iframe
                                                        width="560"
                                                        height="349"
                                                        className="responsive-iframe"
                                                        src="https://cartus.qumucloud.com/view/LAPdobvOgaG?autoPlay=true&embed=true&host=widgets&iframeName=kv-video-player-1-iframe-9247717&autoscale=true&AppProperties.PlayerShowEmbedButton=false" />
                                                </div>
                                            </Grid>
                                        </DialogContent>
                                    </Box>
                                </Dialog>
                                <Grid>
                                    <Paper elevation={0} className="video-tile-hover">
                                        <Typography className="video-title">vivamus faucibus erat vitae.</Typography>
                                        <Typography className="video-publishing-date">JUL 2022</Typography>
                                    </Paper>
                                </Grid>

                            </Paper>
                        </Grid>
                        {/* video 2 tile */}
                        <Grid item xl={4} lg={12} md={12} sm={12} xs={12}>
                            <Paper elevation={0} variant="outlined" className="video-container">
                                <Button className="button-container" onClick={handleClickOpen}>
                                    <img src={video_2}
                                        alt="video"
                                        className="video-img" />
                                    <PlayCircleIcon fontSize="large" className="play-button-icon" />
                                </Button>
                                <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} maxWidth="md">
                                    <Box className="popup-overlay">
                                        <IconButton onClick={handleClose} className="popup-close-icon">
                                            <CloseIcon />
                                        </IconButton>
                                        <DialogContent >
                                            <Grid container className='dialog-content'>
                                                <div className="iframe-class">
                                                    <iframe
                                                        width="560"
                                                        height="349"
                                                        className="responsive-iframe"
                                                        src="https://cartus.qumucloud.com/view/LAPdobvOgaG?autoPlay=true&embed=true&host=widgets&iframeName=kv-video-player-1-iframe-9247717&autoscale=true&AppProperties.PlayerShowEmbedButton=false" />
                                                </div>
                                            </Grid>
                                        </DialogContent>
                                    </Box>
                                </Dialog>
                                <Grid>
                                    <Paper elevation={0} className="video-tile-hover">
                                        <Typography className="video-title">vivamus faucibus erat vitae. urna eget nisi.</Typography>
                                        <Typography className="video-publishing-date">JUL 2022</Typography>
                                    </Paper>
                                </Grid>

                            </Paper>
                        </Grid>
                        {/* video 3 tile */}
                        <Grid item xl={4} lg={12} md={12} sm={12} xs={12}>
                            <Paper elevation={0} variant="outlined" className="video-container">
                                <Button className="button-container" onClick={handleClickOpen}>
                                    <img src={video_3}
                                        alt="video"
                                        className="video-img" />
                                    <PlayCircleIcon fontSize="large" className="play-button-icon" />
                                </Button>
                                <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} maxWidth="md">
                                    <Box className="popup-overlay">
                                        <IconButton onClick={handleClose} className="popup-close-icon">
                                            <CloseIcon />
                                        </IconButton>
                                        <DialogContent >
                                            <Grid container className='dialog-content'>
                                                <div className="iframe-class">
                                                    <iframe
                                                        width="560"
                                                        height="349"
                                                        className="responsive-iframe"
                                                        src="https://cartus.qumucloud.com/view/LAPdobvOgaG?autoPlay=true&embed=true&host=widgets&iframeName=kv-video-player-1-iframe-9247717&autoscale=true&AppProperties.PlayerShowEmbedButton=false" />
                                                </div>
                                            </Grid>
                                        </DialogContent>
                                    </Box>
                                </Dialog>
                                <Grid>
                                    <Paper elevation={0} className="video-tile-hover">
                                        <Typography className="video-title">vivamus faucibus erat vitae. urna eget nisi.</Typography>
                                        <Typography className="video-publishing-date">JUL 2022</Typography>
                                    </Paper>
                                </Grid>

                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} className="more-video-text" locid="moreVideos">
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="more-video-col">
                            <Link>
                                <Typography className="more-video-text">+ more videos</Typography>
                            </Link>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xl={0} lg={3} md={2} sm={2} xs={0}></Grid>
            </Grid>
        </Container>
    )
}