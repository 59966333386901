import React from 'react'
import { Grid} from "@mui/material";
import Button from '@mui/material/Button';
import './ResourceHubHeader.scss'
import hero_image_circle from '../../../assets/Hero image circle.png';
import hero_image_circle1 from '../../../assets/hero-image.png';
import Container from '@material-ui/core/Container';

export default function ResourceHubHeader() {
 
  return (
    <>
    <Container maxWidth="xl" className='container-padding-none'>
      <Grid container spacing={0} className="header-cls" >
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <Grid container item className="header-container">
            {/* **********************Text start******************** */}
            <Grid container xl={5} lg={5} md={12} sm={12}>
              <Grid container xl={12} lg={12} md={9} sm={12} className="header-grey-text">
                <Grid item xl={1} lg={0} md={2} sm={2}></Grid>
                <Grid item xl={10} lg={11} md={9} sm={9} className="padding-box">
                  <h2 className='news'> NEWS</h2>
                  <div className='sub-title'>
                    IRS increases business mileage rate effective july 2022
                  </div>
                  <div className='date'>
                    JUNE 14, 2022
                  </div>
                  <p className="news-description">
                    Last week, the Internal Revenue Service announced a 4-cent increase in both the standard business mileage rate and the move mileage rate effective July 1. For business miles traveled January 1 through June 30, the standard rate is 58.5 cents a mile.
                  </p>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1}></Grid>
              </Grid>
            </Grid>
            {/* **********************Text end******************** */}

            {/* **********************Image start******************** */}
            <Grid container xl={7} lg={7} md={12} sm={12} justifyContent='flex-end'>
              {/* <Grid item xl={0} lg={0} md={2} sm={2} xs={0}></Grid> */}
              <Grid container xl={12} lg={12} md={10} sm={10} xs={12} className="header-heroimage">
                <Grid item xl={6} lg={4} md={5} sm={3} xs={3} className="header-dot-matrix">
                  <img
                    src={hero_image_circle1}
                    alt="Benefits Builder"
                    className="hero-image-circle"
                  />
                </Grid>
                <Grid xl={6} lg={8} md={7} sm={9} xs={9} justifyContent='flex-end' className="header-blue-content">
                  {/* <Grid item xl={1} lg={0} md={0} sm={1}></Grid> */}
                  <Grid item xl={10} lg={10} md={12} sm={10} xs={12} className="padding-box">
                    <h2 className='events'>
                      EVENT
                    </h2>
                    <div className='apac'>
                      APAC focused webinar series
                    </div>
                    <div className='employee'>
                      optimising employee relocation journey
                    </div>

                    <div className='date-blue'>
                      <p>30 June 2022, Wednesday</p>
                      <p>03:00 pm onwards (Singapore Time)</p>
                      <p>1 hour Microsoft Event</p>
                    </div>
                    <Grid>
                      <Button className='btn-blue' disableElevation href="#">
                        register now
                      </Button>
                    </Grid>
                  </Grid>
                  {/* <Grid item xl={2} lg={2} md={0} sm={1}></Grid> */}
                </Grid>
              </Grid>
            </Grid>
            {/* **********************Image start******************** */}
          </Grid>
        </Grid>
      </Grid>
      </Container>
    </>
  )
}


