import React from 'react'
import { Grid, Box, Typography } from "@mui/material";
import './ResourceHubSales.scss'
import sales_img_one from '../../../assets/sales_1.png';
import sales_img_two from '../../../assets/sales_2.png';
import sales_img_three from '../../../assets/sales_3.png';
import sales_img_four from '../../../assets/sales_4.png';
import Container from '@material-ui/core/Container';
export default function ResourcehubSales() {
  return (
    <>
    <div className="sales-cls">
    <Container maxWidth="xl" className='padding-none'>
    <Grid container className='sales-column-reverse'>
          <Grid item container className="container-padding" xl={7} lg={7} md={12} sm={12} xs={12} spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} className="sales-block">
              <Box>
              <Typography className='sub-title'>
              <p className="sales-title">integrated suppliers api bundle</p>
                </Typography>
                <img
                  src={sales_img_one}
                  alt="integrated suppliers api bundle"                                    
                />

              </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} className="sales-block">
              <Box>
              <Typography className='sub-title'>
                  <p className="sales-title" >benefits builder</p>
                  <p className="sales-description">
                  Powered by MovePro360, Benefits Builder delivers the two most-requested capabilities in relocation today: cost management and personalization.
                  </p>
                </Typography>
                <img 
                  src={sales_img_two}
                  alt="benefits builder"                                    
                />

              </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} className="sales-block">
              <Box>
              <Typography className='sub-title'>
                  <p className="sales-title">distributed workforce solutions</p>

                </Typography>
                <img
                  src={sales_img_three}
                  alt="distributed workforce solutions"                                    
                />

              </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} className="sales-block">
              <Box>
              <Typography className='sub-title'>
                 <p className="sales-title" > movepro 360</p>
                </Typography>
                <img
                  src={sales_img_four}
                  alt="benefits builder"                                    
                />

              </Box>
            </Grid>

          </Grid>
          <Grid item xl={1} lg={1} md={12} sm={12} xs={12}></Grid>
          <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <h2 className="sales-collateral-title">Sales Collateral</h2>
          </Grid>
          
        </Grid>
        </Container>
        </div>
    </>
  )
}


