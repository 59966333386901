import React, { useEffect, useState } from "react";
import Carousel from 'react-elastic-carousel';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ImageOne from '../../../../assets/Blog Landing page 428p_image 1.png';
import ImageTwo from '../../../../assets/Blog Landing page 428p_image 2.png';
import ImageThree from '../../../../assets/Blog Landing page 428p_image 3.png';
import ImageFour from '../../../../assets/Blog Landing page 428p_image 4.png';
import ImageFive from '../../../../assets/Blog Landing page 428p_image 5.png';
import RightArrow from '../../../../assets/right arrow.png';
import './ResourceHubBlog.scss'
import Container from '@material-ui/core/Container';


export default function CarouselComp({ pageTemplateData }) {

    const breakPoints = [

        // { width: 200, itemsToShow: 1, itemsToScroll: 1 },
        // { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        // { width: 630, itemsToShow: 2, itemsToScroll: 2 },
        // { width: 850, itemsToShow: 3, itemsToScroll: 3 },
        // { width: 1150, itemsToShow: 3, itemsToScroll: 3 },
        // { width: 1450, itemsToShow: 5, itemsToScroll: 5 },
        // { width: 1750, itemsToShow: 5, itemsToScroll: 5 },
        { width: 1, itemsToShow: 1 }, // works for 428
        { width: 428, itemsToShow: 1 }, // works for 600
        { width: 500, itemsToShow: 2 }, // works for 905
        { width: 768, itemsToShow: 3 }, // works for 1240 and above
         { width: 900, itemsToShow: 4 },
        { width: 1100, itemsToShow: 4 },
    ];

    const state = {
        items: [
            {
                id: 1,
                image: ImageOne,
                bookmark: 'FEATURED',
                title: "Out Now!",
                description: "flexible mobility white paper : building budgets"
            },
            {
                id: 2,
                image: ImageTwo,
                bookmark: 'FEATURED',
                title: "Hear us on Friday’s",
                description: " expert series: moving in podcast"
            },
            {
                id: 3,
                image: ImageThree,
                bookmark: 'FEATURED',
                title: "mobility supply chain: what’s happening in the wider industry?",
                pubDate: 'June 16',
                description: "Our latest Mobility Supply Chain Guide looks at the specific supply chain challenges within the wider industry, what Cartus is doing to meet these challenges, and key recommendations to help organizations."
            },
            {
                id: 4,
                image: ImageFour,
                bookmark: 'FEATURED',
                title: "Out Now!",
                description: "flexible mobility white paper : building budgets"
            },
            {
                id: 5,
                image: ImageFive,
                bookmark: 'FEATURED',
                title: "Out Now!",
                description: "flexible mobility white paper : building budgets"
            },
            {
                id: 6,
                image: ImageOne,
                bookmark: 'FEATURED',
                title: "Out Now!",
                // pubDate: 'June 16',
                description: "Flexible Mobility White paper : Building Budgets"
            },
            {
                id: 7,
                image: ImageTwo,
                bookmark: 'FEATURED',
                title: "Out Now!",
                // pubDate: 'June 16',
                description: "flexible mobility white paper : building budgets"
            },
            {
                id: 8,
                image: ImageFour,
                bookmark: 'FEATURED',
                title: "Out Now!",
                // pubDate: 'June 16',
                description: "flexible mobility white paper : building budgets"
            },
        ]
    }

    const { items } = state;
    return (
        <Container maxWidth="xl" className="container-padding-left-none">
            <Grid container className="blog-cls">
                <Grid item container xl={2} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={12} lg={12} md={10} sm={11} xs={12} className="blogsCol">
                        <h2 className='title'>blogs</h2>
                    </Grid>
                </Grid>
                <Grid item container xl={10} lg={12} md={12} sm={12} xs={12} className="atCenter">
                    <Grid item xl={0} lg={0} md={0} sm={0} xs={0}></Grid>
                    <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="carousel-main-cont">
                            <Carousel slidesToScroll={3} breakPoints={breakPoints} pagination={true} >
                                {
                                    items.map((item, i) => <Item key={i} item={item} />)
                                }
                            </Carousel>
                        </Grid>
                    </Grid>

                    {/* More Blogs Text */}
                   
                    <Grid container spacing={1} className="more-blog-text">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="more-blog-col">
                   <Typography className="more-blog-text">+ more blogs</Typography>
                </Grid>
              </Grid>

                </Grid>

            </Grid>
        </Container>
    )


    function Item(props) {
        console.log("data", props)
        return (
            <>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="colsAlignment">
                    <Card className="root">
                        <CardActionArea>
                            <div className="blogImage">
                                <CardMedia component="img"
                                    image={props.item.image}
                                />
                                <div className="featureTxtPos">
                                    <Typography className="featured-txt">
                                        {props.item.bookmark}
                                    </Typography>
                                </div>
                            </div>
                            <CardContent className="cardContent">
                                <Grid container spacing={2} >
                                    <Grid item direction="column" xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Typography gutterBottom className="blogTitle">
                                            {props.item.title}
                                        </Typography>
                                        <Typography gutterBottom className="blogDate">
                                            {props.item.pubDate}
                                        </Typography>
                                        <Typography component="p"
                                            className="blogDescription">
                                            {props.item.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </>
        )
    }
}