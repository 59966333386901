import React from 'react'
import Layout from '../../staticcomponents/layout'
import ResourceHubHeader from './ResourceHubHeader'
import ResourceHubInsights from './ResourceHubInsights'
import CarouselComp from './Blog/carousel'
import ResourceHubpodcast from './ResouceHubpodcast'
import ResourcehubResearch from './Research'
import ResourcehubSales from './Sales'
import ResourceHubVideo from './Video'
import './resourcehub1.scss'


export default function ResourceHub(){
    return (
        <>
        <Layout>
            <div className='ResourceHub_main'>
                <ResourceHubHeader/>
                <div className='capsules-bg-right'>
                <ResourceHubInsights/>
                </div>
                <div className='bg-donuts'>
                    <CarouselComp/>
                    <ResourceHubpodcast/>
                    <ResourcehubResearch/>
                </div>
                <ResourcehubSales />
                <div className='bg-donuts-right'>
                    <ResourceHubVideo/>
                </div>                
            </div>

        
        </Layout>
        </>
    )
}