import React from 'react'
import { Grid, Typography, CardMedia, Card, CardContent, Button } from '@material-ui/core';
import PodcastTile from '../../../staticcomponents/PodcastTile';
import PodcastTileImg from '../../../staticcomponents/PodcastTileImg';
import Eventswebinarlanding from '../../../assets/eventswebinarlanding.png';
import { Link } from 'gatsby';
import './ResourceHubPodcast.scss'
import Container from '@material-ui/core/Container';
export default function ResourceHubpodcast({ context }) {
  return (
    <>
    <Container maxWidth="xl" className='padding-none'>
      <Grid container className="podcast-cls">
        <Grid container className="container-padding">

          <Grid item xl={7} lg={12} md={12} sm={12} xs={12}>
            <Grid xl={12} lg={12} md={12} sm={10} xs={12}>
              <h2 className='title'>events and webinars</h2>
              <Grid container className="event-card-container">
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className="card-main-section">
                <Card className="event-card">
                    <CardContent className="card-content">
                        <Grid item container spacing={2}>
                            <Grid item direction="column">
                                <div className="events-web-title">
                                    relocation playbook webinar series
                                </div>
                                <div className="events-web-paragraph">
                                    Reflecting on the findings in Cartus’ latest Relocation Playbook, our webinar invites multinational organizations Mastercard, Textron, and Westinghouse Electric Company to share their experiences of the current mobility landscape and what solutions they are implementing to meet today’s challenges.
                                </div>
                                <div>
                                    <Button variant="outlined" className='events-web-button'>replay </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className="card-main-section">
                <Card className="event-card">
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="240"
                        image={Eventswebinarlanding}
                    />
                    <CardContent className="card-content">
                        <Grid item container spacing={2}>
                            <Grid item direction="column">
                                <div className="events-web-title">
                                    join cartus at HRO’S talent mobility 2022
                                </div>
                                <div className="events-web-date">
                                    THURSDAY 26 MAY 2022
                                </div>
                            </Grid>

                        </Grid>

                    </CardContent>
                </Card>
                </div>
            </Grid>
            </Grid>
            <Grid container spacing={1} className="more-podcast-text" locid="morePodcasts">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="more-podcasts-col">
                  <Link>
                    <Typography className="more-event-text">+ more events and webinar</Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={0} lg={0} md={0} sm={2} xs={0}></Grid>

          </Grid>
          {/* podcasts start */}
          <Grid item xl={5} lg={12} md={12} sm={12} xs={12} className="podcast-left-padding">
            <h2 className='title'>podcasts</h2>

            <Grid container className="podcast-container">
              {/* episode-1  */}
              <Grid item xl={12} lg={6} md={12} sm={12} xs={12}>
                  <PodcastTile />
               
              </Grid>
              {/* episode-2  */}
              <Grid item xl={12} lg={6} md={12} sm={12} xs={12}>
                  <PodcastTileImg />
              </Grid>

              <Grid container spacing={1} className="more-podcast-text" locid="morePodcasts">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="more-podcasts-col">
                  <Link>
                    <Typography className="more-podcasts-text">+ more podcasts</Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* podcasts end */}
        </Grid>
      </Grid>
      </Container>
    </>
  )
}