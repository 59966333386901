import React from 'react'
import { Grid, Typography } from "@mui/material";
import './ResourceHubInsights.scss'
import Container from '@material-ui/core/Container';

export default function ResourceHubInsights() {
  return (
    <>
         {/* insights hub --start-   */}
         <Container maxWidth="xl" className='container-padding-none'>
         <Grid container className="insights-hub-cls">
          <Grid container item xl={12} lg={12}>
          <Grid item xl={5} lg={5} md={6} sm={9} xs={11}>
              <div className="insight-hub-text">
              <h2 className="title">insights hub</h2>
              <div className="description">
                collection of all impressions, thoughts from our knowledgeable folks
              </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        </Container>
        {/* insights hub --end-   */}
    </>
  )
}


